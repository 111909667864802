import api from "../utils/api";
import cnpj_ws from "../utils/cnpj_ws";
import {
  BoxParam,
  CustomKitObject,
  InvertersParam,
} from "../views/dashboard/customKit/CustomKitTypes";

import smaugApi from "../utils/smaugApi";
import {
  ClientTokenValidationType,
  ClienteSendTokenType,
  CompleteSimulationType,
  GetStatusType,
  SaveProposalType,
  SimulationType,
} from "../views/dashboard/financing/losango/losagoTypes";

// Login

export const loginUser = (data) => api.post("login", data);
export const registerUser = (data) => api.post("cadastrar-integrador", data);
export const verifyEmail = (data) => api.post("verifica-email", data);
export const checkToken = () => api.post("check-token");
export const lostPassword = (data) => api.post("esqueceu-password", data);
export const deleteAccount = (code: string) =>
  api.delete(`/user/deleteIntegradorEVendedores/${code}`);
export const resetPassword = (data) => api.post("reset-password", data);

// Contrato Venda Direta
export const createContract = (data) =>
  api.post("contratodigital/criarContratoDigital", data);
export const getUuidContract = (data) =>
  api.post("contratodigital/getUuidContrato", data);

// Orcamento

export const getBudgets = (data) => api.post(`orcamento/list`, data);
export const getBudget = (id) => api.get(`orcamento/${id}`);
export const saveBudget = (data) => api.post("orcamento/save", data);
export const deleteBudget = (id: string) =>
  api.delete(`orcamento/delete/${id}`);
export const saveCustomerBudget = (data) =>
  api.post("orcamento/save-customer", data);
export const removeItemBudget = (id, product) =>
  api.delete(`orcamento/removerItem/${id}/${product}`);
export const convertOrder = (id, formObservations = null) =>
  api.put(`orcamento/converte-pedido/${id}`, formObservations);
export const calcularFrete = (data) => api.post("frete/calcularnota", data);
export const calcularFreteParam = (data) =>
  api.post("frete/calcularnotaPorParametros", data);

// Clientes

export const getCustomers = (data) => api.post("pessoa/listar", data);
export const getCustomer = (id) => api.get(`pessoa/showPessoa/${id}`);
export const saveCustomerPj = (data) => api.post("pessoa/saveClientePj", data);
export const saveCustomerPf = (data) => api.post("pessoa/saveClientePF", data);
export const searchCustomers = (data) => api.post("pessoa/buscarCliente", data);
export const cnpjSearch = (data) =>
  cnpj_ws.get(
    `cnpj/${data}?token=0xBhL8MXgKzDF1gaxCtGd81LuFmStKTl7SZSx4hGR1Ao`
  );
export const validarIEPorEstado = (id) =>
  api.get(`pessoa/validarIEPorEstado/${id}`);

// Endereço

export const createAddress = (data) => api.post("endereco", data);
export const updateAddress = (data, id) => api.put(`endereco/${id}`, data);
export const deleteAddress = (id) => api.delete(`endereco/${id}`);
export const novoEndereco = (data) => api.post("endereco/novoEndereco", data);
export const desativarEndereco = (data) =>
  api.delete(`endereco/desativarEndereco/${data}`);
export const updateEndereco = (data) =>
  api.post("endereco/updateEndereco", data);

export const addressSearch = (cep) => api.get(`cep?cep=${cep}`);

export const editContact = (data) => api.post("cliente/editarContato", data);

export const mainActivity = () => api.get("principalAtividade/index");
export const fieldActivity = () => api.get("ramoAtividade/index");

// Usuarios Admin

export const getUsersAdmin = () =>
  api.get("user/obterUsuariosVinculadosAoAdministrador");

export const getUserAdmin = (id) => api.get(`user/getUserById/${id}`);

export const editUserAdmin = (data, id) =>
  api.post(`user/updateUserByAdmin/${id}`, data);

export const createUserAdmin = (data) =>
  api.post("user/createUserByAdmin", data);

export const deleteUserAdmin = (id) =>
  api.delete(`user/deleteUserByAdmin/${id}`);

// Calculadora
export const calcularPotencia = (data) =>
  api.post("calculadora/calcularPotencia", data);

export const listaGeradoresPorPotencia = (data) =>
  api.post("calculadora/listaGeradoresPorPotencia", data);

export const buscaGeradorSolar = (data) =>
  api.post("calculadora/buscaGeradorSolar", data);

export const buscaGeradorEstrutura = (data) =>
  api.post("calculadora/buscaGeradorEstrutura", data);

export const buscaGeradorEstruturaRelIntegrador = (data) =>
  api.post("calculadora/buscaGeradorEstruturaRelIntegrador", data);

export const buscarGeradorEstruturaLista = (data) =>
  api.post("calculadora/buscaGeradorEstruturaList", data);

// Catalog
export const catalogSearch = (data) => api.post("catalog/search", data);

//Relatorios
export const getPdfRelatorioPedido = (data, options) =>
  api.post("relatorio/relatorioPedidosPDF", data, options);
export const getPdfRelatorioVendaDireta = (data, options) =>
  api.post("relatorio/relatorioPremioVendaDiretaPDF", data, options);
export const listAllReports = (data) => api.post("relatorio", data);
export const listAllHighlightReports = () => api.get("relatorio/destaques");
export const downloadPdf = (id) =>
  api.get(`relatorio/gerarRelatorio/${id}/pdf`);
export const listAllFormatsReport = () =>
  api.get("relatorio/formatosRelatorio");
export const listAllDataFiltersReports = () => api.get("relatorio/initDados");
export const saveReport = (data) => {
  api.post("relatorio/store", data);
};
export const updateReport = (data) => {
  api.put("relatorio/update", data);
};
export const getReport = (id) => api.get(`relatorio/buscarRelatorio/${id}`);
export const loadReport = (id) => api.get(`relatorio/show/${id}`);
export const searchReport = (type, formatType, data) =>
  api.post(`relatorio/buscarDadosRelatorio/${type}/${formatType}`, data);
export const deleteReport = (id) => api.delete(`relatorio/delete/${id}`);
// Pedidos
export const getPedidos = (data) => api.post("pedido/getPedidos", data);
export const getResumoPedido = (code) =>
  api.get(`pedido/getResumoPedido/${code}`);
export const getVisaoGeralPedido = (data) =>
  api.post("pedido/getVisaoGeralPedido", data);
export const getGeradorBySku = (data) =>
  api.post("pedido/getGeradorBySku", data);
export const getDadosProdutoPedido = (data) =>
  api.post("pedido/getDadosProdutoBySku", data);
export const enviarEmailPedido = (data) =>
  api.post("pedido/enviarEmailPedido", data);
export const cancelarPedido = (pedido) =>
  api.put(
    `orcamento/cancela-pedido/${pedido.cd_pedidovenda}/${pedido.cd_empresa}/${pedido.cd_filial}`
  );
export const abrirNovoPedido = (pedido) =>
  api.put(
    `orcamento/reabre-pedido/${pedido.cd_pedidovenda}/${pedido.cd_empresa}/${pedido.cd_filial}`
  );
export const getPdfPedido = (pedido) =>
  api.get(
    `pedido/getPdfPedido/${pedido.cd_empresa}/${pedido.cd_filial}/${pedido.cd_pedidovenda}`
  );
export const getXmlPedido = (pedido) =>
  api.get(
    `pedido/getXmlPedido/${pedido.cd_empresa}/${pedido.cd_filial}/${pedido.cd_pedidovenda}`
  );

export const listFilterPedido = (filter, cache) =>
  api.post(`pedido/list?${cache}`, filter);
export const getDataSheets = (filter) =>
  api.post("pedido/getDataSheets", filter);
export const getVlTotalPedidoQtTotalPedido = () =>
  api.post("pedido/getVlTotalPedidoQtTotalPedido");

// Pagamentos
export const getPaymentAccounts = () => api.get("financeiro/contas");
export const getPaymentMethods = () => api.get("financeiro/formas-pgto");

// Financiamento BV
export const financingList = (data) => api.post("partner/bv/propostas", data);
export const financingPreAnalise = (data) =>
  api.post("partner/bv/pre-analise", data);
export const financingSimulacao = (data) =>
  api.post("partner/bv/simulacao", data);
export const financingEstadoCivil = () => api.get("partner/bv/estado-civil");
export const financingTiposResidencia = () =>
  api.get("partner/bv/tipos-residencia");
export const financingNacionalidade = () =>
  api.get("partner/bv/nacionalidades");
export const financingTiposProfissao = () =>
  api.get("partner/bv/tipos-profissao");
export const financingProfissoes = () => api.get("partner/bv/profissoes");
export const financingCidades = (data) => api.post("partner/bv/cidades", data);
export const financingPropostaPF = (data) =>
  api.post("partner/bv/proposta-fisica", data);
export const financingPropostaPJ = (data) =>
  api.post("partner/bv/proposta-juridica", data);
export const financingGetProposta = (id) =>
  api.get(`partner/bv/proposta/${id}`);
export const financingPropostaEmail = (data) =>
  api.post("partner/bv/simulacao", data);
export const enviarEmailSimulacao = (data) =>
  api.post("partner/bv/enviar-email-simulacao", data);
export const financingPedidoOrcamento = (data) =>
  api.post("partner/bv/dados-pedido-orcamento", data);

// Financiamento Losango
export const financingLosangoList = (data) =>
  api.post("partner/losango/propostas", data);
export const saveLosangoProposal = (data: SaveProposalType) =>
  api.post("partner/losango/saveProposta", data);
// Perfil

export const getProfile = () => api.get("/me");
export const getProfileStatistic = () => api.get("/me/estatisticas");
export const sendLogoProfile = (data) =>
  api.post("/me/uploadlogo", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
export const removeLogoProfile = () => api.delete("/me/removelogo");

// Frete
export const calculaFreteUF = (data) => api.post("frete/calcular-uf", data);
export const calcShipping = (data) => api.post("frete/calcularnota", data);
export const calcShippingBudget = (data) =>
  api.post("frete/calcularnotaPorOrcamento", data);

// Dashboard Summary
export const dashboardSummary = (year, month) =>
  api.get(`dashboard/summary/${year}/${month}`);

export const getPremioMes = (month, year) =>
  api.get(`dashboard/vendadireta/${month}/${year}`);

export const getBanners = () => api.get("dashboard/banners");

// PIX

export const dynamicPix = (data) => api.post("pix/gerarPixDinamico", data);
export const getPix = (orderNumber: string) =>
  api.get(`pix/consultarPixDinamico/${orderNumber}`);
export const queryPix = (data) => api.post("pix/query", data);
export const searchPix = (id) => api.post(`pix/${id}`);

// Premio Venda Direta

export const updateAward = (data) => api.post("me/atualizapremio", data);
export const getAwards = (data) => api.post("pedido/vendadireta/list", data);
export const getAward = (id) => api.get(`pedido/vendadireta/show/${id}`);
export const getAwardAzux = (cd_pedido) =>
  api.get(`pedido/vendadireta/data/${cd_pedido}`);
export const getAwardStatistics = (data) =>
  api.post("pedido/vendadireta/estatistica", data);
export const listStatusAward = () => api.get("pedido/vendadireta/status");
export const saveAwardMessageAndNf = (data) =>
  api.post("pedido/vendadireta/save", data);

export const listFilterPedidoVendedor = (filter, cache) =>
  api.post(`pedido/listVendedor?${cache}`, filter);
export const getEnderecoIntegrador = (filter) =>
  api.post("pedido/getEnderecoIntegrador", filter);

//Documentos representante
export const saveDocsRepresentante = (data) =>
  api.post("representante/documentos", data);

// Kit Personalizado
export const getPanels = (
  desc: string,
  type: string,
  order: string,
  uf: string
) =>
  api.get(
    `kitpersonalizado/getPainel?descricao=${desc}&tipo=${type}&ordenacao=${order}&uf=${uf}`
  );
export const getInverters = (obj: InvertersParam) =>
  api.post("kitpersonalizado/getInversor", obj);
export const getStringBoxes = (invertersArray: BoxParam) =>
  api.post(`kitpersonalizado/getStringBox`, invertersArray);
export const getFrontBoxes = (params: BoxParam) => api.post(`kitpersonalizado/getFrontBox`, params);
export const getStructureTypes = () =>
  api.get("kitpersonalizado/getListaEstruturas");
export const getStructure = (
  structure: string,
  desc: string,
  type: string,
  order: string,
  uf: string
) =>
  api.get(
    `kitpersonalizado/getEstrutura?estrutura=${structure}&descricao=${desc}&tipo=${type}&ordenacao=${order}&uf=${uf}`
  );
export const getCables = (uf) => api.get(`kitpersonalizado/getCabos/${uf}`);
export const getConnectors = (uf) =>
  api.get(`kitpersonalizado/getConector/${uf}`);
export const generateCustomKit = (products: CustomKitObject) =>
  api.post("kitpersonalizado/gerarKitPersonalizado", products);
export const getTerminals = (uf) =>
  api.get(`kitpersonalizado/getTerminal/${uf}`);

// Cartão de crédito rede
export const savePaymentLink = (data) =>
  api.post("orcamento/savePaymentLink", data);
export const getPaymentLink = (data) => smaugApi.post("rede/link", data);

// Financiamento Losango
export const losangoSimulation = (data: SimulationType) =>
  smaugApi.post("losango-simulation", data);
export const sendValidationCode = (data: ClienteSendTokenType) =>
  smaugApi.post("losango-client-auth", data);
export const verifyValidationCode = (data: ClientTokenValidationType) =>
  smaugApi.post("losango-client-validation", data);
export const losangoCompleteSimulation = (data: CompleteSimulationType) =>
  smaugApi.post("losango-completa", data);
export const verifyProposalStatus = (data: GetStatusType) =>
  smaugApi.post("losango-status", data);
// Endereço

export const saveEnderecoFrete = (data) =>
  api.post("enderecofrete/salvar", data);
